import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';
import Cart from 'containers/Cart';
import { StorefrontContext } from 'providers/storefront';
import PreviewMode from 'components/PreviewMode';
import Navigation from './Header/Navigation';
import NavigationMobile from './Header/NavigationMobile';
import Footer from './Footer';
import Head from './Head';
import MobileHeader from './MobileHeader';
import { useRouter } from 'next/router';
import { cleanupChatBot, loadChatBot } from 'helpers/chatBotKnowledgeHub';
import Script from 'next/script';
import TiktokPixel from 'tiktok-pixel';

const Layout = ({ children, global, preview }) => {
  const headerRef = useRef('');
  const {
    header,
    footer,
    rtsProducts,
    customProducts,
    workstationProducts,
    customLaptops,
  } = global || {};
  const { isMenuOpen, setIsMenuOpen } = useContext(StorefrontContext);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const router = useRouter();

  const [isKnowledgeHub, setIsKnowledgeHub] = useState(false);

  useEffect(() => {
    if (!router.isReady) return;

    if (router.pathname.includes('knowledge-hub')) {
      setIsKnowledgeHub(true);
    } else {
      setIsKnowledgeHub(false);
    }
  }, [router.isReady, router.pathname]);

  useEffect(() => {
    if (isKnowledgeHub) {
      loadChatBot();
    } else {
      cleanupChatBot();
    }

    router.events.on('routeChangeStart', (url) => {
      if (!url.startsWith('/knowledge-hub')) {
        cleanupChatBot();
      }
    });

    return () => {
      cleanupChatBot();
      router.events.off('routeChangeStart', cleanupChatBot);
    };
  }, [isKnowledgeHub]);

  useEffect(() => {
    TiktokPixel.init(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID);
  }, []);

  return (
    <>
      <Head />
      {process.env.NEXT_PUBLIC_FB_PIXEL_ID && (
        <Script
          id="facebook-pixel"
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s){
                  if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)
                }(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}');
                fbq('track', 'PageView');
              `,
          }}
          strategy="lazyOnload"
        />
      )}
      <Script
        id="google-analytics"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
								window.dataLayer = window.dataLayer || [];
								function gtag(){dataLayer.push(arguments);}
								gtag('js', new Date());
								gtag('config', 'AW-770063361');
							`,
        }}
      />
      {!isKnowledgeHub && global && (
        <MobileHeader
          header={header}
          openMenu={openMenu}
          closeMenu={closeMenu}
          isChecked={isMenuOpen}
          rtsProducts={rtsProducts}
          customProducts={customProducts}
          customLaptops={customLaptops}
          workstationProducts={workstationProducts}
        />
      )}
      <NavigationMobile
        header={header}
        isOpen={isMenuOpen}
        openMenu={openMenu}
        closeMenu={closeMenu}
      />
      {!isKnowledgeHub && global && (
        <Navigation
          header={header}
          opacityRef={headerRef}
          rtsProducts={rtsProducts}
          customProducts={customProducts}
          customLaptops={customLaptops}
          workstationProducts={workstationProducts}
        />
      )}
      <div ref={headerRef}>
        {children}
        {!isKnowledgeHub && (
          <>
            <Footer footer={footer} />
            <Cart />
          </>
        )}
        <PreviewMode preview={preview} />
      </div>

      <style jsx>
        {`
          @media (max-width: ${breakPoints.medium}) {
            .header-nav {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

Layout.propTypes = {
  preview: PropTypes.bool,
};

Layout.defaultProps = {
  preview: false,
};

export default Layout;
